'use client'
import { cn } from 'lib/utils'
import { usePathname } from 'next/dist/client/components/navigation'
import Link from 'next/link'
import { DeveloperDocs } from 'lib/developer/getDeveloperDocuments'
import { useEffect, useRef, useState } from 'react'
import CollapseExpandIcon from './collapseExpandIcon'
import slugify from 'slugify'
import { Fragment } from 'react'

type Link = {
  url: string
  title: string
}

type Links = Link[]

const links: Links = [
  {
    url: '/developer',
    title: 'openFPGA',
  },
  {
    url: '/developer/spacewar',
    title: 'Spacewar! History',
  },
  {
    url: '/developer/docs',
    title: 'Docs',
  },
  {
    url: 'https://github.com/open-fpga',
    title: 'Resources',
  },
]

export default function Subnav({
  items,
  currentSlug,
  currentSubSlug,
}: {
  items?: DeveloperDocs
  currentSlug?: string
  currentSubSlug?: string
}) {
  const subnavRef = useRef<HTMLDivElement | null>(null)
  const [stuck, setStuck] = useState(false)
  const [mounted, setMounted] = useState(false)
  const pathname = usePathname()
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
    }
  }, [])

  useEffect(() => {
    const ref = subnavRef.current
    if (ref && mounted) {
      const observer = new IntersectionObserver(
        ([el]) => {
          setStuck(el.intersectionRatio < 1)
        },
        {
          root: null,
          threshold: [1],
          rootMargin: '0px 0px 0px 0px',
        },
      )
      observer.observe(ref)
      return () => observer.unobserve(ref)
    }
  }, [subnavRef.current])

  return (
    <div
      className={cn(
        'z-50 w-full md:justify-center flex flex-row border-y-[0.05vw] border-[rgba(255,255,255,0.12)]',
        'sticky top-[-1px] bg-transparent',
        stuck && 'bg-[rgb(0,0,0,0.3)] backdrop-blur-xl border-t-transparent',
        expanded && items?.length && 'h-screen overflow-y-auto',
        expanded ? 'bg-[rgb(0,0,0,0.3)] backdrop-blur-xl z-10 gap-1' : 'gap-4',
      )}
      ref={subnavRef}
    >
      <div
        className={cn(
          'fmtext-14 md:ftext-10 flex gap-y-fm8 md:gap-y-0 md:gap-x-f16',
          expanded && 'flex-col pb-fm10',
        )}
      >
        {links.map((link, i) => {
          const active =
            pathname === link.url ||
            (link.url.includes('/docs') && pathname?.startsWith(link.url))
          return (
            <Fragment key={link.url}>
              <Link
                href={link.url}
                className={cn(
                  'px-fm20 md:px-f8 py-fm8 md:py-f10 first:ml-0 md:block hover:text-white transition-colors',
                  active && 'md:border-b-white md:border-b-[0.204vw]',
                  active ? 'text-white block' : 'text-white/45',
                  !active && !expanded && 'hidden',
                )}
              >
                {link.title}
              </Link>
              {expanded &&
                link.url.includes('/docs') &&
                items?.map((item) => {
                  const headers = item.content.value.document.children.filter(
                    (a) => a.type === 'heading' && a.level === 2,
                  )
                  return (
                    <div
                      key={item.slug}
                      className={cn(currentSlug === item.slug && 'mb-6')}
                    >
                      <div>
                        <Link
                          href={`/developer/docs/${item.slug}`}
                          className={cn(
                            currentSlug === item.slug && 'font-bold',
                            'flex text-white/45',
                          )}
                        >
                          <CollapseExpandIcon
                            active={currentSlug === item.slug}
                          />
                          {item.title}
                        </Link>
                      </div>
                      <div className={cn('grid grid-flow-row gap-1 my-2 ml-8')}>
                        {currentSlug === item.slug &&
                          headers.map((header, i) => {
                            const url = `#${slugify(
                              header.children[0]!.value!,
                            )}`
                            return (
                              <div key={i}>
                                <Link
                                  href={url}
                                  className="ml-2 text-white/45 hover:text-white"
                                >
                                  {header.children[0]!.value}
                                </Link>
                              </div>
                            )
                          })}
                      </div>
                      {currentSlug === item.slug &&
                        item.children.map((child) => {
                          const headers =
                            child.content.value.document.children.filter(
                              (a) => a.type === 'heading' && a.level === 2,
                            )
                          return (
                            <div className="ml-2" key={child.slug}>
                              <Link
                                href={`/developer/docs/${item.slug}/${child.slug}`}
                                className={cn(
                                  currentSubSlug === child.slug && 'font-bold',
                                  'flex',
                                  'text-white/45 hover:text-white',
                                )}
                              >
                                <CollapseExpandIcon
                                  active={currentSubSlug === child.slug}
                                />
                                <div>{child.title}</div>
                              </Link>

                              <div
                                className={cn(
                                  'grid grid-flow-row gap-1 my-2 ml-8',
                                )}
                              >
                                {currentSubSlug === child.slug &&
                                  headers.map((header, i) => {
                                    const url = `#${slugify(
                                      header.children[0]!.value!,
                                    )}`
                                    return (
                                      <div key={i}>
                                        <Link
                                          href={url}
                                          className="ml-2 text-[#808080] hover:text-[#cbcbcb]"
                                        >
                                          {header.children[0]!.value}
                                        </Link>
                                      </div>
                                    )
                                  })}
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  )
                })}
            </Fragment>
          )
        })}
      </div>
      <div className="flex-grow md:flex-grow-0"></div>
      <div className="block md:hidden">
        <button
          onClick={() => setExpanded(!expanded)}
          className={cn(
            'fmtext-14 md:ftext-10 inline-flex justify-center items-center gap-fm8 px-fm20 py-fm9 md:block text-white',
          )}
        >
          more
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="6"
            viewBox="0 0 12 6"
            fill="none"
            className={cn(
              'transition-transform',
              expanded && 'transform rotate-180',
            )}
          >
            <path d="M1 0.5L6 5.5L11 0.5" stroke="white" strokeWidth="0.7" />
          </svg>
        </button>
      </div>
    </div>
  )
}
